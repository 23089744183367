import React from "react"
import { graphql, Link, navigate } from "gatsby"

import titleCase from '../js/titleCase';
import API from '../js/api';

import Layout from "../layouts/layout"
import SEO from "../components/SEO/index.js"
import BreadCrumbs from "../components/BreadCrumbs"
import Node from "../components/Node"

class SVG extends React.Component {
  constructor(props){
    super(props);
    this.saved = API.getSavedVectors().reverse();
  }

  render() {

    return (
      <Layout>
        <BreadCrumbs
          links={[
            {title: "Home"},
            {title: API.get("savedTitle"), link: `/saved/`}
          ]}
        />

        <h1 className="title">{API.get("savedLongTitle")}</h1>
        <p className="description">
          {API.get("savedDesc")}
        </p>
        {this.saved &&
          <div className="nodeListing">
            {this.saved.map((res, i) => {
              return <Node key={i} vector={res} loading={false}/>
            })}
          </div>
        }
        {this.saved.length == 0 &&
          <div className="nothing">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#666" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="bevel"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
            <strong>You don't have any saved vectors!</strong>
            <span>Nothing find in your local storage, try clicking the like icon on a vector to see your saved icons here!</span>
          </div>
        }

        <SEO meta={{
          title: API.get("savedTitle"),
          description: API.get("savedDesc"),
          slug: `https://${API.get("domain")}/saved/`,
          breadCrumbs: [{
            to: `/profile/`,
            title: `Profile`
          },{
            to: `/saved/`,
            title: API.get("savedTitle")
          }]
        }} />
      </Layout>
    )
  }
}

export default SVG
